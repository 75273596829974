import React, {FC, useState} from 'react';
import {Button, Checkbox, Col, Layout, Row, Typography} from "antd";
import {IconSpin} from "../../../icons/icons";
import styles from "./DPAForm.module.scss";
import {CheckboxValueType} from "antd/es/checkbox/Group";

const {Title} = Typography;

interface IDPAFormProps {
	onAgree: (vals: CheckboxValueType[]) => void;
	loading?: boolean;
}

const DPAForm: FC<IDPAFormProps> = ({onAgree, loading}) => {

	const [values, setValues] = useState<CheckboxValueType[]>([]);
	const onGroupChange = (val: CheckboxValueType[]) => setValues(val);
	const disabled: boolean = !(values.includes('terms') && values.includes('dpa'));

	return (
		<Layout className={styles.dpaForm}>
			<Row align={"top"} justify={"center"} style={{paddingTop: '5%'}}>
				<Col xs={22} sm={16} md={14} lg={10} xl={8} xxl={7}>
					<div className={styles.logo}>
						<IconSpin style={{fontSize: 140}}/>
					</div>
					<Title className={styles.h3} level={3}>Welcome to ElBackup</Title>
					<Checkbox.Group className={styles.group} onChange={onGroupChange}>
						<Row>
							<Col span={24} className={styles.antCol}>
								<Checkbox value={"terms"}>
									I have read and accept the&nbsp;
									<a href="https://elbackup.com/terms-of-services/" rel="noreferrer" target="_blank">Terms of
										Service</a>
									&nbsp;and&nbsp;
									<a href="https://elbackup.com/privacy-policy/" rel="noreferrer" target="_blank">Privacy
										Policy</a>.
								</Checkbox>
							</Col>
							<Col span={24} className={styles.antCol}>
								<Checkbox value={"offers"}>
									I want to receive news and promotional offers from ElBackup.
								</Checkbox>
							</Col>
							<Col span={24} className={styles.antCol}>
								<Checkbox value={"dpa"}>
									I have read and accept the&nbsp;<a href="//spinbackup.com/dpa/" rel="noreferrer"
									                                   target="_blank">Data Processing Addendum
									(DPA)</a>.
								</Checkbox>
							</Col>
						</Row>
					</Checkbox.Group>
					<div className={styles.buttons}>
						<Button type={"primary"}
						        size={"large"}
						        disabled={disabled || loading}
						        loading={loading}
						        onClick={() => onAgree(values)}
						>
							Register Account
						</Button>
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default DPAForm;