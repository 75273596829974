import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18">
		<g fill="none" fillRule="evenodd">
			<path fill="currentColor" fillRule="nonzero"
			      d="M5.308 4.34a7.357 7.357 0 014.8-2.65 7.301 7.301 0 015.428 1.5 7.3 7.3 0 012.765 4.902c.037.297.055.597.055.896l-1.634.001a.172.172 0 00-.136.278l2.393 3.038a.172.172 0 00.268 0l2.4-3.044c.089-.113.01-.278-.134-.276l-1.532.002a8.946 8.946 0 00-3.443-7.082C12.678-1.11 7.116-.463 4.033 3.334a.172.172 0 00.027.243l1.01.79a.17.17 0 00.238-.026zM17.94 14.424l-1.01-.79a.17.17 0 00-.238.026 7.336 7.336 0 01-2.115 1.774 7.333 7.333 0 01-2.685.877 7.312 7.312 0 01-5.429-1.5 7.308 7.308 0 01-2.767-4.903 7.38 7.38 0 01-.054-.896l1.634-.001a.172.172 0 00.136-.278L3.019 5.694a.172.172 0 00-.268 0L.351 8.74c-.088.114-.008.28.135.277l1.531-.003a8.95 8.95 0 003.445 7.08c3.861 3.017 9.423 2.367 12.505-1.428a.172.172 0 00-.027-.243z"/>
		</g>
	</svg>
);
const IconBackup: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconBackup;