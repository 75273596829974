import {getBaseRoute, getRoute, RouteName} from "../constants/Routing";

const storageKey: string = "redirectUri";
class Redirector {

	static getUri = (): string => {
		let uri = localStorage.getItem(storageKey) ?? getBaseRoute().url;
		if(uri) {
			return uri;
		}
		return getRoute(RouteName.Dashboard).url ?? "";
	}

	static storeUri = (uri:string) => {
		localStorage.setItem(storageKey, uri);
	}

	static clear = () => {
		localStorage.removeItem(storageKey);
	}
}
export default Redirector;