import React, {CSSProperties, FC} from "react";
import {Col, Row, Spin} from "../antd";
import {IconSpin} from "../icons/icons";

interface ILoaderProps {
	readonly title?: string | boolean;
	readonly col?: number;
	readonly children?: ReadonlyArray<JSX.Element> | JSX.Element;
	readonly showLogo?: boolean;
	readonly height?: number;
	readonly width?: number;

}

const Loading: FC<ILoaderProps> = props => {

	const {
		title,
		col,
		showLogo,
		width,
		height
	} = props;

	const cssStyles: CSSProperties = {
		height: height ? height + "px" : "100vh",
		width: width ? width + "px" : ""
	};
	const text: string | null = title ? title as string : null;

	return (
		<Row align={"middle"} justify={"center"} style={cssStyles}>
			<Col span={col || 1} style={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column'
			}}>
				{showLogo ?
					<div>
						<IconSpin style={{fontSize: 120}}/>
					</div>
					: null}
				<Spin style={{
					marginTop: showLogo ? "-15px" : "",
					marginBottom: text ? "9px" : ""
				}}/>
				{text ?
					<div>{text}</div>
					: null
				}
			</Col>
		</Row>
	)
};
export default Loading;