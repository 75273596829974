import React, {FC} from 'react';
import {Button, Col, Form, Layout, Row, Typography} from "antd";
import {IconSpin} from "../../../icons/icons";
import styles from "./DPAForm.module.scss";
import MFACodeInput from "../../../components/MFACodeInput";

const {Title} = Typography;

interface IMFAFormProps {
	onSubmit: (code: string) => void;
	onChange: (code: string) => void;
	error: string | null;
	disabled?: boolean
}

const MFAForm: FC<IMFAFormProps> = ({onSubmit, onChange, error, disabled}) => {


	const [form] = Form.useForm();

	const handleSubmit = (evt: any): void => {
		const code: string = form.getFieldValue("mfa_code");
		if (code.length === 6) {
			onSubmit(code);
		}
	};

	return (
		<Layout className={styles.dpaForm}>
			<Row align={"top"} justify={"center"} style={{paddingTop: '5%'}}>
				<Col xs={22} sm={16} md={14} lg={9} xl={7} xxl={5}>
					<div className={styles.logo}>
						<IconSpin style={{fontSize: 140}}/>
					</div>
					<Form
						form={form}
						layout="vertical"
						onFinish={handleSubmit}
					>
						<Form.Item name={"mfa_code"}>
							<MFACodeInput
								title={<h3 style={{textAlign: "center"}}>2-Step verification</h3>}
								numInputs={6}
								description={"Enter 6 digit code from your app"}
								hasError={!!error}
								errorMessage={error}
								onSubmit={(code) => onSubmit(code)}
								autoSubmit={true}
							/>
						</Form.Item>
						<div style={{textAlign: "center", margin: "10px"}}>
							<Button type={"primary"} htmlType="submit" disabled={disabled}>Continue</Button>
						</div>
					</Form>
				</Col>
			</Row>
		</Layout>
	);
};

export default MFAForm;