import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round"
	     strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 467 467">
		<path fill="none" d="M0 0h1080v1920H0z"/>
		<g transform="matrix(1.53115 0 0 1.45483 -525.184 -1120.218)">
			<ellipse cx="495.5" cy="930.5" fill="#3a8cfd" rx="152.5" ry="160.5"/>
			<clipPath id="a">
				<ellipse cx="495.5" cy="930.5" rx="152.5" ry="160.5"/>
			</clipPath>
			<g clipPath="url(#a)">
				<path fill="#2f7cef"
				      d="M454.022 1025.389L598.2 870.044l87.273 89.721-144.178 155.345zM388.42 951.222l43.67-46.253 57.212 59.834-43.67 46.253z"/>
			</g>
		</g>
		<ellipse cx="407.5" cy="959" fill="#ffbf00" rx="41.5" ry="42"
		         transform="matrix(1.15663 0 0 1.16667 -360.325 -879.833)"/>
		<path fill="#fff"
		      d="M331.95 134.559c6.69-5.95 15.457-9.559 25.05-9.559 20.973 0 38 17.251 38 38.5 0 12.824-6.202 24.191-15.729 31.189L214.525 361l-.326-.32A38.154 38.154 0 01187 372c-20.973 0-38-16.8-38-37.5 0-10.57 4.439-20.12 11.577-26.94l-.111-.11 171.373-173 .111.109z"/>
	</svg>
);

const IconTasks: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconTasks;