import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round"
	     strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 175 175">
		<circle cx="87.5" cy="87.5" r="87.5" fill="none"/>
		<path fillRule="nonzero"
		      d="M78.97 62.542h-2.482v2.447h2.482v-2.447zm22.459 12.305h2.494v-2.459h-2.494v2.459zm0 14.753h2.494v-2.47h-2.494v2.47zm-2.494-19.67h4.988v-2.447h-4.988v2.447zm-14.976 9.811v-2.458h-2.495v2.458h2.495zm-4.989 4.894h4.989v-2.447h-2.495v-2.447H78.97v4.894zm20-31.905H91.5v7.353h7.47V52.73zm2.494 34.4v-2.447h-5.011v2.447h5.011zm-27.505-2.495v-2.447h2.482v-2.447h2.494v-2.458h2.494v-4.895H88.9v-4.952h-2.494v2.458h-2.494v-9.811h-2.483v-4.894h2.482v-7.4h-2.482v4.906h-2.494v-4.906H71.5v4.906h2.494v-2.353h2.447v4.847h2.494v7.353h2.494v2.447h-2.494v4.905h-2.494v-4.905h-2.482v-2.447H71.5v4.894h2.494v2.458H71.5v7.389h2.494v-4.895h2.447v4.895h-2.482v2.458H71.5v7.353h4.941v-2.459h-2.482zm19.999 0h2.495v-4.894h-2.542l.047 4.894zm0 0h-2.529v-2.447h-2.494v4.906h-7.471v2.447h4.977v4.906h2.494v2.447h2.494v-4.906h10v-2.447h-7.471v-4.906zm-2.482-7.352h4.977v2.458h2.494v-7.353h-2.542v-4.905h-2.494v7.364h-7.47v2.447h2.494v2.459h2.494l.047-2.47zM71.5 57.636h4.941v-2.447H71.5v2.447zm7.506 31.952V87.13h-2.518v2.458H71.5v4.894h4.941v2.459h2.494v-4.906h2.494v-2.447h-2.423zm7.47-9.847v2.447h2.494v-2.447h-2.494zM53.994 67.483H51.5v12.294h2.494V67.483zm0 29.411h12.494V84.635H53.994v12.259zM51.5 82.188h17.47v17.2H51.5v-17.2zm49.929-31.905H88.935v12.294h12.494V50.283zm2.494-2.494v17.2H86.441v-17.2h17.482zM63.994 52.73h-7.518v7.353h7.471V52.73h.047zm17.47 39.305v2.447h2.495v-2.447h-2.495zm-24.988 2.447h7.471V87.13h-7.471v7.352zm10.012-44.199H53.994v12.294h12.494V50.283zm2.482 14.706H51.5v-17.2h17.47v17.2zM61.5 69.93v-2.447h-5.024v4.906h2.495V69.93H61.5zm2.494-2.447v2.447H71.5v-2.447h-7.506zm20 31.941h2.447v-4.895h-2.482l.035 4.895zm9.953 0v-2.447h-2.518v2.447h2.518zm-22.447 0h4.941v-2.447H71.5v2.447zm29.964-2.483h2.494v-2.459h-2.529l.035 2.459zM66.488 74.847h2.482v-2.459h-2.482v2.459zm29.965 22.094h2.482v-2.459h-2.482v2.459zM63.994 74.847h2.494v2.447h2.482v2.459H56.476v-2.47h2.495v-4.895h4.976l.047 2.459z"/>
		<path fill="#fff" fillRule="nonzero"
		      d="M118.641 110.906H86.876c-2.762.013-5.05-2.238-5.082-5V59.671c.032-2.762 2.32-5.013 5.082-5h31.765c2.764-.019 5.056 2.236 5.082 5v46.235c-.026 2.764-2.318 5.019-5.082 5z"/>
		<path fill="#ecad66" fillRule="nonzero"
		      d="M106.088 135.823l10.906-14.117-29.024-.165c-1.67-1.259-3.67-1.6-3.67-3.612l-.33-31.388c-.388-1.341-1.67-2.976-5.682-2.682-.176 9.576-.988 30.753-.988 30.753s-.341 4.364 1.412 6.305a11.234 11.234 0 004.4 2.812l-2.353 5.506s2.694 4.576 9.976 6.682a30.168 30.168 0 0014.67.706M130.17 61.906c-2.953-2.776-6.035.247-7.141 1.424l-1.788 1.953v12.776l8.541-9.541c1.235-1.471 2.953-4.259.388-6.612z"/>
		<path fillRule="nonzero"
		      d="M115.664 56.965H89.852c-3.149 0-5.741 2.592-5.741 5.741V116.6c-.025 3.143 2.54 5.75 5.683 5.776h25.87c3.143 0 5.73-2.586 5.73-5.729l-.001-.047V62.706c-.012-3.14-2.589-5.722-5.729-5.741z"/>
		<path fill="#3473b9" d="M87.606 62.706h30.282v51.635H87.606z"/>
		<path fill="#fff" fillRule="nonzero"
		      d="M99.005 82.765a4.322 4.322 0 011.177-2.93 3.603 3.603 0 012.623-1.176 3.53 3.53 0 012.612 1.176 4.18 4.18 0 011.177 2.93v4.047h-7.589v-4.047zm12.142 4.094h-1.177v-4.047a7.545 7.545 0 00-2.07-5.271 6.99 6.99 0 00-10.2 0 7.755 7.755 0 00-2.071 5.271v4.047h-1.176a.922.922 0 00-.918.918v10.729c0 .503.414.918.918.918h16.694a.934.934 0 00.929-.918V87.788l.001-.048a.886.886 0 00-.882-.882l-.048.001z"/>
		<path fill="#ecad66" fillRule="nonzero"
		      d="M119.817 80.318l-1.459 1.882c-1.176 1.53-2.541 4.177-.212 6.482 2.871 2.824 5.883-.211 6.953-1.447l1.247-1.623c1.177-1.494 2.871-4.318.377-6.706-2.835-2.882-5.812.177-6.906 1.412z"/>
		<path fill="#ecad66" fillRule="nonzero"
		      d="M119.817 92.494l-1.459 1.871c-1.176 1.541-2.541 4.188-.212 6.494 2.871 2.823 5.883-.212 6.953-1.447l1.247-1.624c1.177-1.494 2.871-4.317.377-6.706-2.835-2.905-5.812.118-6.906 1.412z"/>
		<path fill="#ecad66" fillRule="nonzero"
		      d="M119.817 104.435l-1.459 1.883c-1.176 1.541-2.541 4.188-.212 6.494 2.871 2.811 5.883-.212 6.953-1.459l1.247-1.612c1.177-1.494 2.871-4.318.377-6.706-2.835-2.859-5.812.212-6.906 1.4z"/>
	</svg>
);

const IconMfa: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconMfa;