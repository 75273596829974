import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={"1em"} height={"1em"} viewBox={"0 0 103 103"} version="1.1">
		<g>
			<path fill="#e9eaeb"
			      d="m51.781 0c-28.597 0-51.781 23.184-51.781 51.781s23.184 51.781 51.781 51.781 51.781-23.184 51.781-51.781-23.181-51.781-51.779-51.781zm0.28125 31.625 9.5938 7.0312 9.5938 7.0625-3.7188 11.344-3.75 11.281l-11.906-0.031-11.906-0.062-3.657-11.344-3.593-11.344 9.6562-7 9.6875-6.9375z"/>
			<path fill="#0dab62"
			      d="m46.031 71.906c-2.5812 0-3.9361 1.7885-2.9688 4.5938l6.6562 19.344c0.68102 0.03121 1.3738 0.0625 2.0625 0.0625 17.121 0 31.963-9.7503 39.281-24h-45.031z"/>
			<path fill="#ffd34e"
			      d="m69.026 63.672c-0.82729 2.445 0.43259 4.3017 3.3999 4.2845l20.457-0.10521c0.24784-0.63509 0.49951-1.2813 0.72026-1.9337 5.4875-16.218 1.0085-33.401-10.144-44.901l-14.433 42.656z"/>
			<path fill="#448afd"
			      d="m30.847 52.424c-0.787-2.458-2.904-3.203-5.28-1.426l-16.391 12.241c0.17806 0.65807 0.35962 1.3273 0.56975 1.9832 5.2242 16.305 19.037 27.464 34.841 30.086l-13.74-42.884z"/>
			<path fill="#9b479f"
			      d="m44.32 32.185c2.0659-1.5475 2.078-3.7912-0.378-5.4565l-16.925-11.49c-0.56377 0.38331-1.137 0.77358-1.6882 1.1865-13.703 10.265-19.736 26.966-17.05 42.759l36.041-26.998z"/>
			<path fill="#e04a3f"
			      d="m68.282 38.84c2.0889 1.5162 4.236 0.86467 5.101-1.9738l5.976-19.565c-0.533-0.425-1.075-0.857-1.632-1.262-13.856-10.057-31.595-10.884-45.888-3.651l36.443 26.452z"/>
		</g>
	</svg>
);
const IconPicasa: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconPicasa;


