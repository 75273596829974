import React, {FC} from "react";
import Icon from "@ant-design/icons";
import {IconComponentProps} from "@ant-design/icons/lib/components/Icon";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} fill="currentColor" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.04366 9.50004C2.04366 5.64783 5.14588 2.54561 8.99809 2.54561C10.089 2.54561 11.1799 2.81834 12.1685 3.29561H11.623C11.2821 3.29561 11.0094 3.56832 11.0094 3.90923C11.0094 4.25013 11.2821 4.52286 11.623 4.52286H13.498C13.9071 4.52286 14.2139 4.18195 14.2139 3.80696V1.93199C14.2139 1.59109 13.9412 1.31836 13.6003 1.31836C13.2594 1.31836 12.9867 1.59109 12.9867 1.93199V2.37516C12.9526 2.34107 12.9185 2.34108 12.8844 2.30699C11.6912 1.65927 10.3617 1.31836 8.99809 1.31836C4.49816 1.31836 0.816406 5.00012 0.816406 9.50004C0.816406 10.8637 1.15731 12.2273 1.80502 13.4204C1.9073 13.625 2.11184 13.7273 2.35047 13.7273C2.45274 13.7273 2.55501 13.6932 2.65728 13.6591C2.9641 13.4886 3.06637 13.1136 2.89592 12.8409C2.31638 11.8182 2.04366 10.6591 2.04366 9.50004Z"/>
		<path d="M16.1951 5.57855C16.0247 5.27174 15.6497 5.16947 15.3769 5.33993C15.0701 5.51038 14.9679 5.88537 15.1383 6.15809C15.6838 7.1808 15.9906 8.30578 15.9906 9.49894C15.9906 13.3512 12.8543 16.4534 9.03614 16.4534C7.94525 16.4534 6.85435 16.1807 5.86573 15.7034H6.41118C6.75208 15.7034 7.02481 15.4307 7.02481 15.0898C7.02481 14.7489 6.75208 14.4761 6.41118 14.4761H4.53621C4.12713 14.4761 3.82031 14.817 3.82031 15.192V17.067C3.82031 17.4079 4.09304 17.6806 4.43394 17.6806C4.77485 17.6806 5.04757 17.4079 5.04757 17.067V16.6238C5.08166 16.6579 5.11575 16.6579 5.14984 16.692C6.30892 17.3397 7.63844 17.6806 9.00205 17.6806C13.502 17.6806 17.1837 13.9989 17.1837 9.49894C17.1837 8.13533 16.8428 6.77171 16.1951 5.57855Z"/>
		<path strokeWidth=".4" d="M8.25781 12.6367C8.25781 12.8191 8.33025 12.9939 8.45918 13.1229C8.58811 13.2518 8.76298 13.3242 8.94531 13.3242C9.12765 13.3242 9.30252 13.2518 9.43145 13.1229C9.56038 12.9939 9.63281 12.8191 9.63281 12.6367C9.63281 12.4544 9.56038 12.2795 9.43145 12.1506C9.30252 12.0217 9.12765 11.9492 8.94531 11.9492C8.76298 11.9492 8.58811 12.0217 8.45918 12.1506C8.33025 12.2795 8.25781 12.4544 8.25781 12.6367ZM8.60156 10.832H9.28906C9.33633 10.832 9.375 10.7934 9.375 10.7461V5.76172C9.375 5.71445 9.33633 5.67578 9.28906 5.67578H8.60156C8.5543 5.67578 8.51562 5.71445 8.51562 5.76172V10.7461C8.51562 10.7934 8.5543 10.832 8.60156 10.832Z"/>
	</svg>
);
const IconAdvancedBackup: FC<IconComponentProps> = props => <Icon component={SVG} {...props} />;
export default IconAdvancedBackup;
