import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} viewBox="0 0 88 78" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g id="Risk-assessment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-22" transform="translate(-326.650000, 0.000000)" fillRule="nonzero">
				<g id="gdrive" transform="translate(327.000000, 0.000000)">
					<path
						d="M6.6,66.85 L10.45,73.5 C11.25,74.9 12.4,76 13.75,76.8 L27.5,53 L0,53 C0,54.55 0.4,56.1 1.2,57.5 L6.6,66.85 Z"
						id="Path" fill="#0066DA"/>
					<path
						d="M73.55,76.8 C74.9,76 76.05,74.9 76.85,73.5 L78.45,70.75 L86.1,57.5 C86.9,56.1 87.3,54.55 87.3,53 L59.798,53 L65.65,64.5 L73.55,76.8 Z"
						id="Path" fill="#EA4335"/>
					<path
						d="M43.65,25 L57.4,1.2 C56.05,0.4 54.5,0 52.9,0 L34.4,0 C32.8,0 31.25,0.45 29.9,1.2 L43.65,25 Z"
						id="Path" fill="#00832D"/>
					<path
						d="M59.8,53 L27.5,53 L13.75,76.8 C15.1,77.6 16.65,78 18.25,78 L69.05,78 C70.65,78 72.2,77.55 73.55,76.8 L59.8,53 Z"
						id="Path" fill="#2684FC"/>
					<path
						d="M73.4,26.5 L60.7,4.5 C59.9,3.1 58.75,2 57.4,1.2 L43.65,25 L59.8,53 L87.25,53 C87.25,51.45 86.85,49.9 86.05,48.5 L73.4,26.5 Z"
						id="Path" fill="#FFBA00"/>
					<path
						d="M29.75,26.5 L17.05,4.5 C16.25,3.1 15.1,2 13.75,1.2 L1.95399252e-13,25 L16.15,53 L43.6,53 C43.6,51.45 43.2,49.9 42.4,48.5 L29.75,26.5 Z"
						id="Path" fill="#00AC47"
						transform="translate(21.800000, 27.100000) scale(-1, 1) translate(-21.800000, -27.100000) "/>
				</g>
			</g>
		</g>
	</svg>
);
const IconDrive: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconDrive;
