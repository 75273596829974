import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} viewBox="0 0 76 76" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g id="Risk-assessment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-25" fillRule="nonzero">
				<polygon id="Path" fill="#FFFFFF" points="58 18 18 18 18 58 58 58"/>
				<path
					d="M23.11,44.595 L26.58,43.165 C26.895,44.365 27.445,45.295 28.23,45.955 C29.01,46.615 29.96,46.94 31.07,46.94 C32.205,46.94 33.18,46.595 33.995,45.905 C34.81,45.215 35.22,44.335 35.22,43.27 C35.22,42.18 34.79,41.29 33.93,40.6 C33.07,39.91 31.99,39.565 30.7,39.565 L28.695,39.565 L28.695,36.13 L30.495,36.13 C31.605,36.13 32.54,35.83 33.3,35.23 C34.06,34.63 34.44,33.81 34.44,32.765 C34.44,31.835 34.1,31.095 33.42,30.54 C32.74,29.985 31.88,29.705 30.835,29.705 C29.815,29.705 29.005,29.975 28.405,30.52 C27.8503208,30.84844 27.41,31.9737524 27.41,32.52 L23.975,31.09 C24.43,29.8 25.265,28.66 26.49,27.675 C27.715,26.69 29.28,26.195 31.18,26.195 C32.585,26.195 33.85,26.465 34.97,27.01 C36.09,27.555 36.97,28.31 37.605,29.27 C38.24,30.235 38.555,31.315 38.555,32.515 C38.555,33.74 38.26,34.775 37.67,35.625 C37.08,36.475 36.355,37.125 35.495,37.58 L35.495,37.785 C38.555,39.3193492 38.585,41.925 38.585,43.285 C38.585,44.645 38.24,45.86 37.55,46.925 C36.86,47.99 35.905,48.83 34.695,49.44 C33.48,50.05 32.115,50.36 30.6,50.36 C28.845,50.365 27.225,49.86 25.73,48.85 C24.235,47.84 23.675,46.545 23.11,44.595 Z M47.52,31.81 L43.71,34.565 L41.805,31.675 L48.64,26.745 L51.26,26.745 L51.26,50 L47.52,50 L47.52,31.81 Z"
					id="Shape" fill="#4285F4"/>
				<polygon id="Path" fill="#34A853" points="58 58 18 58 18 76 58 76"/>
				<path d="M58,0 L6,0 C2.685,0 0,2.685 0,6 L0,58 L18,58 L18,18 L58,18 L58,0 Z" id="Path" fill="#4285F4"/>
				<path d="M0,58 L0,70 C0,73.315 2.685,76 6,76 L18,76 L18,58 L0,58 Z" id="Path" fill="#188038"/>
				<polygon id="Path" fill="#FBBC04" points="76 18 58 18 58 58 76 58"/>
				<path d="M76,18 L76,6 C76,2.685 73.315,0 70,0 L58,0 L58,18 L76,18 Z" id="Path" fill="#1967D2"/>
				<polygon id="Path" fill="#EA4335" points="58 76 76 58 58 58"/>
			</g>
		</g>
	</svg>
);
const IconCalendar: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconCalendar;