import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} fill="currentColor" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.946 9.50004C2.946 5.64783 6.04822 2.54561 9.90043 2.54561C10.9913 2.54561 12.0822 2.81834 13.0708 3.29561H12.5254C12.1845 3.29561 11.9118 3.56832 11.9118 3.90923C11.9118 4.25013 12.1845 4.52286 12.5254 4.52286H14.4004C14.8094 4.52286 15.1163 4.18195 15.1163 3.80696V1.93199C15.1163 1.59109 14.8435 1.31836 14.5026 1.31836C14.1617 1.31836 13.889 1.59109 13.889 1.93199V2.37516C13.8549 2.34107 13.8208 2.34108 13.7867 2.30699C12.5936 1.65927 11.264 1.31836 9.90043 1.31836C5.40051 1.31836 1.71875 5.00012 1.71875 9.50004C1.71875 10.8637 2.05965 12.2273 2.70737 13.4204C2.80964 13.625 3.01418 13.7273 3.25282 13.7273C3.35509 13.7273 3.45736 13.6932 3.55963 13.6591C3.86644 13.4886 3.96871 13.1136 3.79826 12.8409C3.21873 11.8182 2.946 10.6591 2.946 9.50004Z"/>
		<path d="M17.0936 5.57855C16.9231 5.27174 16.5481 5.16947 16.2754 5.33993C15.9686 5.51038 15.8663 5.88537 16.0367 6.15809C16.5822 7.1808 16.889 8.30578 16.889 9.49894C16.889 13.3512 13.7527 16.4534 9.93458 16.4534C8.84369 16.4534 7.75279 16.1807 6.76417 15.7034H7.30962C7.65052 15.7034 7.92325 15.4307 7.92325 15.0898C7.92325 14.7489 7.65052 14.4761 7.30962 14.4761H5.43465C5.02557 14.4761 4.71875 14.817 4.71875 15.192V17.067C4.71875 17.4079 4.99148 17.6806 5.33238 17.6806C5.67329 17.6806 5.946 17.4079 5.946 17.067V16.6238C5.98009 16.6579 6.01419 16.6579 6.04828 16.692C7.20735 17.3397 8.53687 17.6806 9.90049 17.6806C14.4004 17.6806 18.0822 13.9989 18.0822 9.49894C18.0822 8.13533 17.7413 6.77171 17.0936 5.57855Z"/>
		<path d="M11.8106 7.45436L8.87884 10.1816L8.06067 9.09071C7.85613 8.81798 7.48114 8.74981 7.20841 8.95435C6.93569 9.15889 6.86751 9.53388 7.07206 9.80661L8.2993 11.4429C8.40157 11.5793 8.57203 11.6816 8.74248 11.6816C8.77657 11.6816 8.77656 11.6816 8.81065 11.6816C8.98111 11.6816 9.11747 11.6134 9.21974 11.5111L12.6629 8.30662C12.9015 8.06799 12.9356 7.693 12.697 7.45436C12.4583 7.21573 12.0492 7.21573 11.8106 7.45436Z"/>
	</svg>
);
const IconBackupAll: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconBackupAll;