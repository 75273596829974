import React, {FC} from "react";
import {IIcon} from "../../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" fillRule="evenodd" strokeLinejoin="round"
	     strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 461 149">
		<path fill="#005bac"
		      d="m186 3-23 144h37L224 3h-38Zm-55 0-36 99-4-22-1-5a119 119 0 0 0-50-53l33 125h39L172 3h-41Zm147 40c0-17 36-15 52-6l6-31s-17-6-34-6c-19 0-63 8-63 48 0 37 52 37 52 57 0 19-47 16-62 4l-5 32s16 8 42 8 64-13 64-49c0-38-52-41-52-57ZM431 3h-30c-14 0-18 10-18 10l-56 134h40l7-22h48l5 22h34L431 3Zm-46 93 20-54 11 54h-31Z"/>
		<path fill="#f6ac1d" d="M79 16S78 3 61 3H1L0 6s29 5 57 28c26 21 35 47 35 47L79 16Z"/>
	</svg>
);

const VisaIcon: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default VisaIcon;
