import React, {FC} from "react";
import {IIcon} from "../../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVGIconLabelAll: FC<IIcon> = props => (
	<svg xmlns="http://www.w3.org/2000/svg" width={"1em"} height={"1em"} fill={"currentColor"} viewBox="0 0 24 24">
		<path fill="none" d="M0 0h24v24H0V0z"/>
		<path
			d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 2-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z"/>
	</svg>
);
export const IconLabelAll: FC<IIcon> = props => <Icon component={SVGIconLabelAll} {...props} />;

const SVGIconLabelCategory: FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={"1em"} height={"1em"} fill={"currentColor"} viewBox="0 0 24 24">
		<path fill="none" d="M0 0h24v24H0V0z"/>
		<path
			d="M17.63 5.84C17.27 5.33 16.67 5 16 5L5 5.01C3.9 5.01 3 5.9 3 7v10c0 1.1.9 1.99 2 1.99L16 19c.67 0 1.27-.33 1.63-.84L22 12l-4.37-6.16zM16 17H5V7h11l3.55 5L16 17z"/>
	</svg>
);
export const IconLabelCategory: FC<IIcon> = props => <Icon component={SVGIconLabelCategory} {...props} />;

const SVGIconLabelChat: FC<IIcon> = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={"1em"} height={"1em"} fill="#858585" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0V0z"/>
        <path d="m4 18 2-2h14V4H4z" opacity=".3"/>
        <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/>
    </svg>
);
export const IconLabelChat: FC<IIcon> = props => <Icon component={SVGIconLabelChat} {...props} />;

export const IconLabelDraft: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path d="M12 15.36l-8-5.02V18h16l-.01-7.63z" opacity=".3"/>
		<path
			d="M21.99 8c0-.72-.37-1.35-.94-1.7L12 1 2.95 6.3C2.38 6.65 2 7.28 2 8v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zM12 3.32L19.99 8v.01L12 13 4 8l8-4.68zM4 18v-7.66l8 5.02 7.99-4.99L20 18H4z"/>
	</svg>
);

export const IconLabelImportant: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path d="M15 7H7.89l3.57 5-3.57 5H15l3.55-5z" opacity=".3"/>
		<path
			d="M16.63 5.84C16.27 5.33 15.67 5 15 5H4l5 7-5 6.99h11c.67 0 1.27-.32 1.63-.83L21 12l-4.37-6.16zM15 17H7.89l3.57-5-3.57-5H15l3.55 5L15 17z"/>
	</svg>
);

export const IconLabelInbox: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path
			d="M12.01 18c-1.48 0-2.75-.81-3.45-2H5v3h14v-3h-3.55c-.69 1.19-1.97 2-3.44 2z"
			opacity=".3"
		/>
		<path
			d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-3h3.56c.69 1.19 1.97 2 3.45 2s2.75-.81 3.45-2H19v3zm0-5h-5c0 1.1-.9 2-2 2s-2-.9-2-2H5V5h14v9z"/>
	</svg>
);

export const IconLabelSent: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path d="M4 8.25l7.51 1-7.5-3.22zm.01 9.72l7.5-3.22-7.51 1z" opacity=".3"/>
		<path
			d="M2.01 3L2 10l15 2-15 2 .01 7L23 12 2.01 3zM4 8.25V6.03l7.51 3.22-7.51-1zm.01 9.72v-2.22l7.51-1-7.51 3.22z"/>
	</svg>
);

export const IconLabelSnoozed: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path
			d="M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4.25 12.15L11 13V7h1.5v5.25l4.5 2.67-.75 1.23z"
			opacity=".3"
		/>
		<path
			d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
	</svg>
);

export const IconLabelSpam: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path
			d="M9.1 5L5 9.1v5.8L9.1 19h5.8l4.1-4.1V9.1L14.9 5H9.1zM12 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm1-3h-2V7h2v7z"
			opacity=".3"
		/>
		<path
			d="M15.73 3H8.27L3 8.27v7.46L8.27 21h7.46L21 15.73V8.27L15.73 3zM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5h5.8L19 9.1v5.8z"/>
		<circle cx="12" cy="16" r="1"/>
		<path d="M11 7h2v7h-2z"/>
	</svg>
);

export const IconLabelStarred: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path
			d="M17.11 10.83l-2.47-.21-1.2-.1-.47-1.11L12 7.13l-.97 2.28-.47 1.11-1.2.1-2.47.21 1.88 1.63.91.79-.27 1.17-.57 2.42 2.13-1.28 1.03-.63 1.03.63 2.13 1.28-.57-2.42-.27-1.17.91-.79z"
			opacity=".3"
		/>
		<path
			d="M22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.64-7.03L22 9.24zm-7.41 5.18l.56 2.41-2.12-1.28-1.03-.62-1.03.62-2.12 1.28.56-2.41.27-1.18-.91-.79-1.88-1.63 2.47-.21 1.2-.1.47-1.11.97-2.27.97 2.29.47 1.11 1.2.1 2.47.21-1.88 1.63-.91.79.27 1.16z"/>
	</svg>
);

export const IconLabelTrash: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path d="M8 9h8v10H8z" opacity=".3"/>
		<path d="M15.5 4l-1-1h-5l-1 1H5v2h14V4zM6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9z"/>
	</svg>
);

export const IconLabelUnread: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#858585"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0z" fill="none"/>
		<path d="M10 12H6V8H4v12h16V8H10z" opacity=".3"/>
		<path
			d="M20 6H10v2h10v12H4V8h2v4h2V4h6V0H6v6H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2z"/>
	</svg>
);

export const IconLabelSocial: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="black"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<circle cx="9" cy="8.5" opacity=".3" r="1.5"/>
		<path
			d="M4.34 17h9.32c-.84-.58-2.87-1.25-4.66-1.25s-3.82.67-4.66 1.25z"
			opacity=".3"
		/>
		<path
			d="M9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm0 6.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zm11.7-3.19c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z"/>
	</svg>
);

export const IconLabelUpdates: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="black"
		width="20px"
		height="20px"
	>
		<path
			d="M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-4h-2V7h2v6z"
			opacity=".3"
		/>
		<path
			d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-5h2v2h-2zm0-8h2v6h-2z"/>
	</svg>
);

export const IconLabelForums: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="black"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path d="M15 11V4H4v8.17L5.17 11H6z" opacity=".3"/>
		<path
			d="M16 13c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10zm-12-.83V4h11v7H5.17L4 12.17zM22 7c0-.55-.45-1-1-1h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7z"/>
	</svg>
);

export const IconLabelPromotions: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="black"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path
			d="M11 4H4v7l9 9.01L20 13l-9-9zM6.5 8C5.67 8 5 7.33 5 6.5S5.67 5 6.5 5 8 5.67 8 6.5 7.33 8 6.5 8z"
			opacity=".3"
		/>
		<path
			d="M12.41 2.58C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58s1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41s-.23-1.06-.59-1.42l-9-9zM13 20.01L4 11V4h7v-.01l9 9-7 7.02z"/>
		<circle cx="6.5" cy="6.5" r="1.5"/>
	</svg>
);

export const IconLabelPersonal: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="black"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<circle cx="12" cy="8" opacity=".3" r="2"/>
		<path
			d="M12 15c-2.7 0-5.8 1.29-6 2.01V18h12v-1c-.2-.71-3.3-2-6-2z"
			opacity=".3"
		/>
		<path
			d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 7c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4zm6 5H6v-.99c.2-.72 3.3-2.01 6-2.01s5.8 1.29 6 2v1z"/>
	</svg>
);

export const IconLabelFolder: FC<IIcon> = props => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="black"
		width="20px"
		height="20px"
	>
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path d="M11.17 8l-.58-.59L9.17 6H4v12h16V8h-8z" opacity=".3"/>
		<path
			d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V6h5.17l1.41 1.41.59.59H20v10z"/>
	</svg>
);

export const IconLabelShared: FC<IIcon> = props => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
		<path d="M0 0h24v24H0V0z" fill="none"/>
		<path
			d="M11.17 8l-.59-.59L9.17 6H4v12h16V8h-8.83zM19 16v1h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2zm-4-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
			opacity=".3"/>
		<path
			d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V6h5.17l2 2H20v10zm-5-5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-4 3v1h8v-1c0-1.33-2.67-2-4-2s-4 .67-4 2z"/>
	</svg>
);