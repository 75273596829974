import React, {FC, useContext} from 'react';
import {Alert, Button, Col, Layout, Row} from "antd";
import {ArrowLeftOutlined, IconSpin} from "../../../icons/icons";
import styles from "./DPAForm.module.scss";
import {useNavigate} from "react-router-dom";
import {IAppContext} from "../../../context/IAppContext";
import {AppContext} from "../../../context/AppContext";

interface IMFAFormProps {
	error: string
}

const Error: FC<IMFAFormProps> = props => {

	const context: IAppContext = useContext(AppContext);
	const {authUser} = context;
	const {
		error
	} = props;

	const navigate = useNavigate();

	return (
		<Layout className={styles.dpaForm}>
			<Row align={"top"} justify={"center"} style={{paddingTop: '5%'}}>
				<Col xs={22} sm={16} md={14} lg={10} xl={8} xxl={4} style={{textAlign: "center"}}>
					<div className={styles.logo}>
						<IconSpin style={{fontSize: 140}}/>
					</div>
					<Alert message={error} showIcon={true} type={"error"}/>
					{authUser ?
						<Button type={"primary"} onClick={() => navigate("/app/dash")} style={{marginTop: '20px'}}>
							<ArrowLeftOutlined/> Back to Dashboard
						</Button>
					:
						<Button type={"primary"} onClick={() => navigate("/")} style={{marginTop: '20px'}}>
							<ArrowLeftOutlined/> Back to Login
						</Button>
					}
				</Col>
			</Row>
		</Layout>
	);
};

export default Error;