import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {storeToLocalStorage} from "../../tools/Tools";
import {AppConfig} from "../../Config";
import Loading from "../Loading";


const InvitationCallback = () => {
	const {code} = useParams();
	useEffect(() => {
		if (code) {
			storeToLocalStorage('invitation', code);
			window.location.href = AppConfig.getGoogleAuthUrl();
		}
	}, [code]);

	return <Loading col={5} title={"Preparing invitation"} showLogo={true}/>;
};

export default InvitationCallback;
