import {IIcon} from "../interfaces/IIcon";
import {FC} from "react";

const IconDailyView: FC<IIcon> = props => (
	<svg width={"1em"} height={"1em"} xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" fill="currentColor"
	     fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd"
	     viewBox="0 0 64 64" {...props}>
		<path
			d="M62.293 17.195c0-1.582-1.146-2.865-2.56-2.865H3.413C2 14.33.853 15.613.853 17.195v30.57c0 1.583 1.146 2.866 2.56 2.866h56.32c1.414 0 2.56-1.283 2.56-2.866v-30.57Zm-56.32 2.866v24.838h51.2V20.061h-51.2ZM2.56 5.732h58.88c1.413 0 2.56-1.284 2.56-2.866S62.853 0 61.44 0H2.56C1.147 0 0 1.284 0 2.866s1.147 2.866 2.56 2.866Zm0 58.476h58.88c1.413 0 2.56-1.284 2.56-2.866s-1.147-2.866-2.56-2.866H2.56C1.147 58.476 0 59.76 0 61.342s1.147 2.866 2.56 2.866Z"/>
	</svg>
);
export default IconDailyView;