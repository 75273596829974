import {IIcon} from "../interfaces/IIcon";
import {FC} from "react";

const IconMonthlyView: FC<IIcon> = props => (
	<svg width={"1em"} height={"1em"} {...props} fill="currentColor" xmlns="http://www.w3.org/2000/svg"
	     xmlSpace="preserve" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd"
	     viewBox="0 0 64 64">
		<path
			d="M0 31.73V13.3c0-3.891 3.203-7.05 7.149-7.05H56.85c3.947 0 7.15 3.16 7.15 7.05v37.397c0 3.892-3.203 7.052-7.149 7.052H7.15C3.203 57.75 0 54.59 0 50.698V31.73Zm5.053 2.507v16.461c0 1.142.939 2.068 2.096 2.068h12.517l.055-18.439-14.668-.09Zm19.665 18.529h14.553l.102-18.317-14.6-.09-.055 18.407Zm34.23-18.196-14.523-.09-.102 18.286h12.528c1.157 0 2.096-.926 2.096-2.068V34.57ZM44.554 11.234l-.102 18.262 14.494.09V13.3c0-1.141-.939-2.067-2.096-2.067H44.555Zm-19.711 0-.055 18.14 14.611.091.102-18.231H24.844Zm-5.053 0H7.15c-1.157 0-2.096.926-2.096 2.067v15.952l14.683.09.055-18.11Z"/>
	</svg>
);
export default IconMonthlyView;