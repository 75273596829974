import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 350">
		<path fill="#fbbb05"
		      d="M87.5 79.5c48.3 0 87.5 39.2 87.5 87.5v8H8c-4.4 0-8-3.6-8-8 0-48.3 39.2-87.5 87.5-87.5z"/>
		<path fill="#e94335"
		      d="M270.5 87.5c0 48.3-39.2 87.5-87.5 87.5h-8V8c0-4.4 3.6-8 8-8 48.3 0 87.5 39.2 87.5 87.5z"/>
		<path fill="#4285f4"
		      d="M262.5 270.5c-48.3 0-87.5-39.2-87.5-87.5v-8h167c4.4 0 8 3.6 8 8 0 48.3-39.2 87.5-87.5 87.5z"/>
		<path fill="#0f9d58"
		      d="M79.5 262.5c0-48.3 39.2-87.5 87.5-87.5h8v167c0 4.4-3.6 8-8 8-48.3 0-87.5-39.2-87.5-87.5z"/>
	</svg>
);
const IconPhotos: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconPhotos;