import React from 'react';
import {AppConfig} from "../Config";
import styles from "../scss/GoogleSignInButton.module.scss";
import {IconGoogle} from "../icons/icons";

const authUrl: string = AppConfig.getGoogleAuthUrl();

export const GoogleSignInButton: React.FC = () => {
	return (
		<div style={{float: "right"}}>
			<a href={authUrl} className={styles.GoogleSignInButton}>
				<p>
					<span>Sign in with Google</span>
					<span className={styles.GoogleLogo}><IconGoogle/></span>
				</p>
			</a>
		</div>
	);

};