import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";
import Loading from "../../containers/Loading";

import {deleteFromLocalStorage, doAuth, storeToLocalStorage} from "../../tools/Tools";
import DPAForm from "./components/DPAForm";
import MFAForm from "./components/MFAFrom";
import Error from "./components/Error";
import Redirector from "../../tools/Redirector";

const baseUrl = Redirector.getUri();
const url: URL = new URL(window.location.href);
const code: string | null = url.searchParams.get('code');
const errorCode: string | null = url.searchParams.get('error');
const errorParam: string | null = url.searchParams.get('error');

const AuthCallback: React.FC = () => {
	const [showMfa, setShowMfa] = useState<boolean>(false);
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [showRegistration, setShowRegistration] = useState<boolean>(false);
	const [mfaError, setMfaError] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const title: string = code ? "Please wait" : "";
	const [idToken, setIdToken] = useState<string | null>(null);
	const [accessToken, setAccessToken] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [redirectToLogin, setRedirectToLogin] = useState<boolean>(false);

	const makeAuthRequest = (code: string | null, mfaCode: string | null = null) => {
		if (code) {
			setError(null);
			setLoading(true);
			let data = {
				"code": code,
				"agree": localStorage.getItem('agree')?.split(',')
			};
			if(localStorage.getItem('invitation')){
				data["invitation"] = localStorage.getItem('invitation');
			}
			if (mfaCode !== null) {
				data['mfa_code'] = mfaCode;
				data['id_token'] = idToken;
				delete data.code;
			}
			if (accessToken) {
				data['access_token'] = accessToken;
				delete data.code;
			}
			doAuth(data)
				.then((data: any) => {
					if (data && data.token) {
						deleteFromLocalStorage('agree');
						storeToLocalStorage('token', data.token);
						setAuthenticated(true);
					}
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					switch (err.code) {
						case "A403":
							setError(err.message);
							setShowMfa(false);
							break;
						case "M403":
							setError(null);
							setIdToken(err.additional.id_token);
							!showMfa && setShowMfa(true);
							break;
						case "M404":
						case "M405":
							setError(err.message);
							setShowMfa(true);
							setMfaError(true);
							break;
						case "R404":
							setShowRegistration(true);
							setAccessToken(err.additional.access_token);
							setShowMfa(false);
							setMfaError(false);
							break;
						default:
							setError(err.message);
							break;
					}
				});
		} else if(errorParam || errorCode) {
			localStorage.clear();
			setRedirectToLogin(true);
		}
	};

	useEffect(() => {
		makeAuthRequest(code);
	}, []);

	if(authenticated) {
		return <Navigate to={baseUrl}/>;
	}

	if(redirectToLogin) {
		return <Navigate to={"/"}/>;
	}

	return (
		<>
			{error && !showMfa ? <Error error={error}/> : null}
			{showRegistration ?
				<DPAForm
					loading={loading}
					onAgree={(v) => {
						localStorage.setItem('agree', v.join(","));
						if (code) {
							makeAuthRequest(code);
						}
					}}/>
				: null}
			{showMfa ?
				<MFAForm
					onSubmit={mfaCode => makeAuthRequest(code, mfaCode)}
					disabled={loading}
					error={error}
					onChange={() => {
					}}/>
				: null}
			{!authenticated && !error && !showMfa ?
				<Loading col={5} title={title} showLogo={true}/>
				: null}
		</>
	);
};

export default AuthCallback;
