import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVGIcon: FC = () => (
	<svg width={"1em"} height={"1em"} viewBox="0 0 88 66" version="1.1" xmlns="http://www.w3.org/2000/svg">
		<g id="Risk-assessment" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Group-17" transform="translate(0.000000, -0.016168)">
				<path
					d="M6,66.0161678 L20,66.0161678 L20,32.0161678 L0,17.0161678 L0,60.0161678 C0,63.3311678 2.685,66.0161678 6,66.0161678 Z"
					id="Path" fill="#4285F4" fillRule="nonzero"/>
				<path
					d="M68,66.0161678 L82,66.0161678 C85.315,66.0161678 88,63.3311678 88,60.0161678 L88,17.0161678 L68,32.0161678 L68,66.0161678 Z"
					id="Path" fill="#34A853" fillRule="nonzero"/>
				<path
					d="M68,6.01616776 L68,32.0161678 L88,17.0161678 L88,9.01616776 C88,1.60116776 79.535,-2.63383224 73.6,1.81616776 L68,6.01616776 Z"
					id="Path" fill="#FBBC04" fillRule="nonzero"/>
				<polygon id="Path" fill="#EA4335"
				         points="20 32.0161678 20 6.01616776 44 24.0161678 68 6.01616776 68 32.0161678 44 50.0161678"/>
				<path
					d="M0,9.01616776 L0,17.0161678 L20,32.0161678 L20,6.01616776 L14.4,1.81616776 C8.465,-2.63383224 0,1.60116776 0,9.01616776 Z"
					id="Path" fill="#C5221F" fillRule="nonzero"/>
			</g>
		</g>
	</svg>
);
const IconGmail: FC<IIcon> = props => <Icon component={SVGIcon} {...props} />;
export default IconGmail;
