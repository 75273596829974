import {IIcon} from "../interfaces/IIcon";
import {FC} from "react";

const IconWeeklyView: FC<IIcon> = props => (
	<svg width={"1em"} height={"1em"} {...props} fill="currentColor" xmlns="http://www.w3.org/2000/svg"
	     xmlSpace="preserve" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd"
	     viewBox="0 0 64 64">
		<path
			d="M64 8.516A2.52 2.52 0 0 0 61.474 6H2.526A2.521 2.521 0 0 0 0 8.516v46.968A2.52 2.52 0 0 0 2.526 58h58.948A2.521 2.521 0 0 0 64 55.484V8.516Zm-49.405 2.516H5.053v41.936h9.77l-.228-41.936Zm5.052 0 .228 41.936h9.822l-.153-41.936h-9.897Zm14.95 0 .152 41.936h9.446l-.164-41.936h-9.435Zm14.487 0 .163 41.936h9.7V11.032h-9.863Z"/>
	</svg>
);
export default IconWeeklyView;