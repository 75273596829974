import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../containers/Loading";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {IAppContext} from "../../context/IAppContext";
import {AppContext} from "../../context/AppContext";
import Error from "../authCallback/components/Error";
import {Services} from "../../constants/Services";
import {Alert, Button, notification, Result} from "antd";
import {ClockCircleOutlined, DashboardOutlined, IconBackup} from "../../icons/icons";
import {Modal} from "../../antd";

const UPDATE_TOKEN = gql`
    mutation connectToGooglePhotos($code: String!) {
        connectToGooglePhotosAction(input:{
            code : $code
        }){
            action{
                message
            }
        }
    }
`;


const START_TASKS = gql`
    mutation startTasks($services: [String!]) {
        batchStartTasks(input:{
            services: $services
        }){
            startTasks{
                count
            }
        }
    }
`;

const columns = [
	{
		title: 'Services',
		dataIndex: 'label',
		key: 'name',
		width: '100%'
	}
];


const PhotosConnectCallback: React.FC = () => {
	const context: IAppContext = useContext(AppContext);
	const {authUser, refreshInfo} = context;

	const [connected, setConnected] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const [startTasks] = useMutation(START_TASKS, {
		onError: error => {
		},
		onCompleted: data => {
			if (data.batchStartTasks!.startTasks!.count! > 0) {
				refreshInfo().then(r => {
					setLoading(false);
					setShowModal(false);
					notification.success({
						message: 'Backup stared',
						duration: 2
					});
					navigate("/app/dash");
				});
			}
		}
	});

	const [connectToGooglePhotos] = useMutation(UPDATE_TOKEN, {
		onError: (err) => {
			setError(err.message);
		},
		onCompleted: () => {
			refreshInfo().then(() => {
				setConnected(true);
			});
		}
	});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const code = queryParams.get('code');
		if (code) {
			connectToGooglePhotos({
				variables: {
					code: code
				}
			}).then(r => {
			});
		}
	}, [location, history]);

	const onStartTasks = () => {
		setLoading(true);
		startTasks({variables: {services: [Services.SERVICE_GOOGLE_PHOTOS]}}).then(r => {});
	};

	const onModalClose = () => {
		setShowModal(false);
	}

	if(connected) {

		return (
			<>
				<Result
					status="success"
					title="Success"
					subTitle={"Google Photos has been connected successfully. Click the 'Backup Now' button to back up immediately."}
					extra={[
						<Button key="dash" onClick={() => navigate("/app/dash")}>
							<DashboardOutlined/> Go Dashboard
						</Button>,
						<Button key="backup" onClick={() => setShowModal(true)} disabled={showModal}>
							<IconBackup/> Backup now
						</Button>,
						<Button key="settings" onClick={() => navigate("/app/autobackup")}>
							<ClockCircleOutlined/> Autobackup settings
						</Button>,
					]}
				/>

				<Modal
					open={showModal}
					onOk={onStartTasks}
					onCancel={onModalClose}
					title={"Start backup"}
					okText={"Start backup"}
					okButtonProps={{
						loading: loading
					}}
				>
					<>
						<Alert type={"info"} message={<>
							Google photos backup process will start with the current settings. You can change the settings <Button type={"link"} style={{padding: 0,
							margin: 0}} key="backup" onClick={() => navigate("/app/autobackup")}>here.</Button>
						</>} style={{marginBottom:15}}/>
					</>
				</Modal>
			</>
		)
	}

	return (
		<>
			{connected || error ? null : <Loading col={5} title={"Please wait..."} showLogo={true}/>}
			{error ? <Error error={error}/> : null}
		</>
	);
};

export default PhotosConnectCallback;
