import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" height={"1em"} width={"1em"} viewBox="0 0 500 500">
		<defs/>
		<circle cx="250" cy="250" r="250" fill="#1A73E8"/>
		<path fill="#185ABC"
		      d="M332.4 375H170.5c-19.6 0-36.9-11.8-36.9-31.2V358c0 19.4 17.3 34.1 36.9 34.1h161.9c19.6 0 36.9-14.7 36.9-34.1v-14.2c0 19.4-17.3 31.2-36.9 31.2zM250 227.3c-29.3-.1-52.4-20.4-54-48.3v11.4c0 28.9 24.2 54 54 54s54-25.1 54-54V179c-2.3 28.1-24.7 48.4-54 48.3z"/>
		<g fill="#FFF">
			<path
				d="M250 261.4c-57 0-116.5 27.8-116.5 65.3v17c0 19.4 15.9 34.1 35.5 34.1h164.7c19.6 0 35.5-14.7 35.5-34.1v-17c.1-37.6-62.2-65.3-119.2-65.3z"/>
			<circle cx="250" cy="176.1" r="54"/>
		</g>
	</svg>
);

const IconContacts: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconContacts;
