import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVGIcon: FC = () => (
	<svg width={"1em"} height={"1em"} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
		<path fillRule="nonzero"
		      d="M622.999 271.98a193.91 193.91 0 014.101.02c109 1.7 196.9 90.6 196.9 200 0 63.151-29.264 119.443-74.947 156.086a300.921 300.921 0 0185.144 59.814c54.8 54.7 85.8 126.9 87.8 203.9.002.067.003.133.003.2 0 4.389-3.611 8-8 8h-56.003c-4.3 0-7.9-3.4-8-7.7-1.9-58-25.4-112.3-66.7-153.5-42.862-42.863-99.671-66.539-160.298-66.798-60.628.259-117.436 23.935-160.299 66.798-41.3 41.2-64.8 95.5-66.7 153.5-.1 4.3-3.7 7.7-8 7.7h-56.003c-4.388 0-8-3.611-8-8 0-.067.001-.133.003-.2 2-77 33-149.2 87.8-203.9a300.921 300.921 0 0185.144-59.814c-45.682-36.643-74.947-92.935-74.947-156.086 0-109.4 87.9-198.3 196.9-200 1.374-.021 2.745-.028 4.102-.02zM373.5 498.4c.5 4-2.1 7.7-5.9 8.8-39.3 9.9-75.3 30.3-104.7 59.7-41.3 41.2-64.8 95.5-66.7 153.5-.1 4.3-3.7 7.7-8 7.7h-56.103c-4.388 0-8-3.611-8-8 0-.067.001-.133.003-.2 2-77.1 33-149.3 87.8-204 25.4-25.4 54.7-45.7 86.5-60.4-45.3-36.7-74.2-92.7-74.2-155.5 0-110.8 92.4-201.7 203.2-200 76.8 1.2 143.2 45.6 175.7 109.9 2.5 5-.7 11-6.3 11.6-19.1 2-37.7 6.3-55.3 12.4-3.4 1.2-7.3-.1-9.3-3.2-5.7-8.8-12.5-17-20.4-24.4-23.3-21.8-54.1-34.1-86-34.4-35.3-.4-68.5 13.4-93.2 38.7-22.5 23-35.4 53.5-36.3 85.6a127.598 127.598 0 0038.7 95.4c10.8 10.6 23.3 19 36.9 25.1 3.3 1.5 5.2 5.2 4.5 8.8-2.8 15.1-4.3 30.6-4.3 46.5 0 8.9.5 17.7 1.4 26.4zm249.499-154.402l-.299.002c-34.3.4-66.4 14.1-90.4 38.7-22.9 23.5-36 55.2-36.3 88a127.309 127.309 0 0037.5 91.8c23.963 23.963 55.672 37.238 89.499 37.496 33.827-.258 65.536-13.533 89.498-37.496a127.305 127.305 0 0037.5-91.8c-.3-32.8-13.4-64.5-36.3-88-24-24.6-56.1-38.3-90.4-38.7l-.298-.002z"/>
	</svg>
);
const IconUserGroup: FC<IIcon> = props => <Icon component={SVGIcon} {...props} />;
export default IconUserGroup;