import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Loading from "../../containers/Loading";
import {useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {IAppContext, ISchedule} from "../../context/IAppContext";
import {AppContext} from "../../context/AppContext";
import Error from "../authCallback/components/Error";
import {Alert, Button, Col, notification, Result} from "antd";
import {ClockCircleOutlined, DashboardOutlined, IconBackup} from "../../icons/icons";
import {Modal, Row, Table} from "../../antd";
import {Services, ServicesList} from "../../constants/Services";
import {IService, services} from "../../constants/Routing";
import Checkbox from "antd/es/checkbox/Checkbox";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {toggleElement} from "../../tools/Tools";

const UPDATE_TOKEN = gql`
    mutation connectToGoogle($code: String!) {
        connectToGoogleAction(input:{
            code : $code
        }){
            action{
                message
            }
        }
    }
`;

const START_TASKS = gql`
    mutation startTasks($services: [String!]) {
        batchStartTasks(input:{
            services: $services
        }){
            startTasks{
                count
            }
        }
    }
`;

const columns = [
	{
		title: 'Services',
		dataIndex: 'label',
		key: 'name',
		width: '100%'
	}
];

const GoogleConnectCallback: React.FC = () => {
	const context: IAppContext = useContext(AppContext);
	const {authUser, refreshInfo} = context;

	const [connected, setConnected] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [selected, setSelected] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	const [startTasks] = useMutation(START_TASKS, {
		onError: error => {
		},
		onCompleted: data => {
			if (data.batchStartTasks!.startTasks!.count! > 0) {
				refreshInfo().then(r => {
					setLoading(false);
					setShowModal(false);
					notification.success({
						message: 'Backup stared',
						duration: 2
					});
					navigate("/app/dash");
				});
			}
		}
	});

	const [connectToGoogle] = useMutation(UPDATE_TOKEN, {
		onError: (err) => {
			setError(err.message);
		},
		onCompleted: () => {
			refreshInfo().then(() => {
				setConnected(true);
			});
		}
	});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const code = queryParams.get('code');
		if (code) {
			connectToGoogle({
				variables: {
					code: code
				}
			}).then(r => {
			});
		} else {
			navigate("/app/dash");
		}
	}, [location, history]);

	const toggleServiceSelection = (evt: CheckboxChangeEvent, serviceId: string) => {
		const selection = [...toggleElement(selected, serviceId)];
		setSelected(selection);
	};

	const onStartTasks = () => {
		setLoading(true);
		startTasks({variables: {services: selected}}).then(r => {});
	};

	const onModalClose = () => {
		setSelected([]);
		setShowModal(false);
	}

	if(connected) {

		const list: Array<any | null> = authUser?.schedule ?
			ServicesList.map(serviceId => {
				if(serviceId !== Services.SERVICE_GOOGLE_PHOTOS) {
					const schedule = authUser.schedule?.find((schedule: ISchedule) => schedule.serviceId === serviceId);
					const service: IService | null = services.find(service => service.serviceId === serviceId) || null;
					if (schedule && service) {
						return {
							key: serviceId,
							label: <Checkbox disabled={!schedule.active}
							                 onChange={e => toggleServiceSelection(e, schedule.serviceId!)}
							                 checked={selected.indexOf(schedule.serviceId!) !== -1}
							>
								<span key={'i_' + schedule.serviceId}>{service?.icon} {service?.title}</span>
							</Checkbox>,
							info: schedule.formattedSettings,
						}
					}
				}
				return null;
			}).filter(info => info !== null) as Array<any> : [];

		return (
			<>
				<Result
					status="success"
					title="Success"
					subTitle={"Your token has been renewed successfully. Click the 'Backup Now' button to back up immediately."}
					extra={[
						<Button key="dash" onClick={() => navigate("/app/dash")}>
							<DashboardOutlined/> Go Dashboard
						</Button>,
						<Button key="backup" onClick={() => setShowModal(true)} disabled={showModal}>
							<IconBackup/> Backup now
						</Button>,
						<Button key="settings" onClick={() => navigate("/app/autobackup")}>
							<ClockCircleOutlined/> Autobackup settings
						</Button>,
					]}
				/>

				<Modal
					open={showModal}
					onOk={onStartTasks}
					onCancel={onModalClose}
					title={"Start backup"}
					okText={"Start backup"}
					okButtonProps={{
						disabled: selected.length === 0,
						loading: loading
					}}
				>
					<>
						<Alert type={"info"} message={<>
							All backup processes will start with the current settings. You can change the settings <Button type={"link"} style={{padding: 0,
							margin: 0}} key="backup" onClick={() => navigate("/app/autobackup")}>here.</Button>
						</>} style={{marginBottom:15}}/>
						<Row>
							<Col span={24}>
								<Table
									size={'small'}
									dataSource={list}
									pagination={false}
									columns={columns}
								/>
							</Col>
						</Row>
					</>
				</Modal>
			</>
		)
	}

	return (
		<>
			{connected || error ? null : <Loading col={5} title={"Please wait..."} showLogo={true}/>}
			{error ? <Error error={error}/> : null}
		</>
	);
};

export default GoogleConnectCallback;
