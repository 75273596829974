import {useContext, useEffect, useState} from 'react';
import {IAppContext} from "../context/IAppContext";
import {AppContext} from "../context/AppContext";

// const DEVICE_XS = 480;
const DEVICE_SM = 576;
const DEVICE_MD = 768;
const DEVICE_LG = 992;
const DEVICE_XL = 1200;
const DEVICE_XXL = 1600;

export enum DeviceTypes {
	XS = 'XS',
	SM = 'SM',
	MD = 'MD',
	LG = 'LG',
	XL = 'XL',
	XXL = 'XXL'
}

export namespace DeviceTypes {
	export const isLargerThan = (current: string, compare: string) => {
		const keys = Object.keys(DeviceTypes);
		return keys.indexOf(current) > keys.indexOf(compare);
	};
	export const isSmallerThan = (current: string, compare: string) => {
		const keys = Object.keys(DeviceTypes);
		return keys.indexOf(current) < keys.indexOf(compare);
	}
}

interface IDimension {
	min: number;
	max: number;
}

interface IDimensions {
	[key: string]: IDimension
}

const DEVICE_TYPES: IDimensions = {
	[DeviceTypes.XS]: {min: 0, max: DEVICE_SM - 1},
	[DeviceTypes.SM]: {min: DEVICE_SM, max: DEVICE_MD - 1},
	[DeviceTypes.MD]: {min: DEVICE_MD, max: DEVICE_LG - 1},
	[DeviceTypes.LG]: {min: DEVICE_LG, max: DEVICE_XL - 1},
	[DeviceTypes.XL]: {min: DEVICE_XL, max: DEVICE_XXL - 1},
	[DeviceTypes.XXL]: {min: DEVICE_XXL, max: 0}
};

export const detectDeviceType = (wWidth: number): string => {
	let dT: string = DeviceTypes.XXL;
	Object.keys(DeviceTypes).forEach((type: string) => {
		const dimensions: IDimension = DEVICE_TYPES[type];
		if (dimensions) {
			const wMin: number = dimensions.min;
			const wMax: number = dimensions.max;
			if (wMax > 0) {
				if (wWidth >= wMin && wWidth <= wMax) {
					dT = type;
				}
			} else {
				if (wWidth >= wMin) {
					dT = type;
				}
			}
		}
	});
	return dT;
};
const isLargeThan = (current: string, compare: string) => {
	const idx = Object.keys(DeviceTypes);
};
const currentDeviceType: string = detectDeviceType(window.innerWidth);

export const useDeviceTypeGetter = () => {

	const [deviceType, setDeviceType] = useState<string>(currentDeviceType);

	useEffect(() => {
		const resizeHandler = (): void => {
			const currentDeviceType: string = detectDeviceType(window.innerWidth);
			setDeviceType(currentDeviceType);
		};
		resizeHandler();
		window.addEventListener('resize', resizeHandler);
		return () => window.removeEventListener('resize', resizeHandler);
	}, []);

	return deviceType;
}

export const useDeviceType = () => {
	const context: IAppContext = useContext(AppContext);
	return context.ui.deviceType;
};
