import React, {FC} from "react";
import {IIcon} from "../interfaces/IIcon";
import Icon from "@ant-design/icons";

const SVG: FC = () => (
	<svg width={"1em"} height={"1em"} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<path
			d="M44.307 14.548H3.693A3.695 3.695 0 000 18.241v11.518a3.693 3.693 0 003.693 3.692h40.614a3.693 3.693 0 003.692-3.692V18.241a3.693 3.693 0 00-3.692-3.693zM14.71 25.344l-3.615-.634-.023.068 2.463 2.779-2.011 1.176-1.289-3.504-.045.022-1.311 3.481-1.943-1.178 2.417-2.824v-.047l-3.548.657v-2.28l3.503.699.023-.044-2.396-2.849 2.012-1.152 1.267 3.459h.045l1.289-3.459 1.987 1.131-2.44 2.87.021.067 3.594-.723V25.344zm10.269 0l-3.617-.634-.022.068 2.464 2.779-2.011 1.176-1.29-3.504-.044.022-1.311 3.481-1.944-1.178 2.417-2.824v-.047l-3.549.657v-2.28l3.504.699.023-.044-2.396-2.849 2.012-1.152 1.266 3.459h.046l1.289-3.459 1.987 1.131-2.441 2.87.023.067 3.594-.723v2.285zm10.266 0l-3.616-.634-.023.068 2.465 2.779-2.012 1.176-1.287-3.504-.047.022-1.312 3.481-1.943-1.178 2.42-2.824v-.047l-3.551.657v-2.28l3.505.699.023-.044-2.397-2.849 2.014-1.152 1.264 3.459h.046l1.288-3.459 1.988 1.131-2.44 2.87.024.067 3.592-.723v2.283h.001v.002zm7.282 5.063h-5.922v-2.283h5.922v2.283z"/>
	</svg>
);
const IconKey: FC<IIcon> = props => <Icon component={SVG} {...props} />;
export default IconKey;